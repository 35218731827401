<template>
  <div
    v-loading="loading"
    class="login-page"
  >
    <el-form
      ref="loginForm"
      class="auth-form"
      :model="loginForm"
      :rules="rules"
    >
      <h2 class="form-header">
        Login
      </h2>
      <el-form-item
        label="Email address"
        prop="email"
      >
        <el-input
          v-model="loginForm.email"
          type="email"
          placeholder="Enter email address"
          :disabled="loading"
          @keyup.enter.native="onSubmit"
        />
      </el-form-item>
      <el-form-item
        label="Password"
        prop="password"
      >
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="Enter password"
          :disabled="loading"
          @keyup.enter.native="onSubmit"
        />
      </el-form-item>
      <router-link
        :to="{ name: 'auth-forgot-password' }"
        class="link-forgot"
      >
        Forgot your password?
      </router-link>
      <button
        id="submit"
        type="submit"
        class="fgh-button lg fgh-button--primary btn-login"
        :disabled="loading"
        @click.prevent="onSubmit"
      >
        Login
      </button>
      <!-- <facebook-login-button
        v-if="isActiveBrand(['feelgoodhub'])"
        :disabled="loading"
        @success="onFacebookLoginSuccess"
      /> -->
    </el-form>
  </div>
</template>

<script>
import store from '@/store'
import authApi from '@/modules/auth/services/auth.api'
import authRules from '@/modules/auth/services/auth.rules'
// import facebookLoginButton from '@/modules/auth/components/facebook-login-button'

export default {
  components: {
    // facebookLoginButton
  },

  data: () => ({
    loginForm: {
      email: '',
      password: '',
    },
    rules: {
      email: [
        { validator: authRules.validateEmailAddress }
      ],
      password: [
        { validator: authRules.validatePassword }
      ]
    },
    loading: false
  }),

  methods: {
    onSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          authApi.login(this.loginForm.email, this.loginForm.password)
            .then(response => {
              if (response.data.activated === true) {
                store.commit('LOGIN_USER', response.data)
                if (response.data.intro_viewed === false) {
                  this.$router.push({ name: 'feed-welcome' })
                } else {
                  if (store.state.redirectUrl) {
                    var redirectUrl = store.state.redirectUrl
                    store.state.redirectUrl = null
                    this.$router.push(redirectUrl)
                  } else {
                    this.$router.push({ name: 'feed-feed' })
                  }
                }
              } else {
                store.commit('LOGIN_UNVERIFIED_USER', response.data)
                this.$router.push({ name: 'auth-verify-email', params: { id: response.data.id, email: response.data.email } })
              }
              this.loading = false
            }).catch((error) => {
              this.loading = false

              this.$notify.error({
                title: 'Error',
                message: error.response.data.error.message
              })
            })
        }
      })
    },
    // onFacebookLoginSuccess(response) {
    //   this.loading = true
    //   authApi.socialLogin(response.accessToken)
    //     .then(response => {
    //       if (response.data.activated === true) {
    //         store.commit('LOGIN_USER', response.data)
    //         if (response.data.intro_viewed === false) {
    //           this.$router.push({ name: 'feed-welcome' })
    //         } else {
    //           if (store.state.redirectUrl) {
    //             var redirectUrl = store.state.redirectUrl
    //             store.state.redirectUrl = null
    //             this.$router.push(redirectUrl)
    //           } else {
    //             this.$router.push({ name: 'feed-feed' })
    //           }
    //         }
    //       }
    //       this.loading = false
    //     }).catch((error) => {
    //       this.loading = false
    //       if (error.response.status == 401) {
    //         this.$notify.error({
    //           title: 'Error',
    //           message: 'No account found with the details provided. Why not register?',
    //         })
    //       } else {
    //         this.$notify.error({
    //           title: 'Error',
    //           message: 'An unknown error occurred. Please try again later.',
    //         })
    //       }
    //     })

    // }
  }
}
</script>

<style lang="scss">
.login-page {
  .auth-form {
    max-width: 400px;
  }

  .link-forgot {
    width: 100%;
    display: block;
    text-align: right;
    margin-bottom: 30px;
    font-size: 14px;
  }

  .btn-login, .btn-social-login {
    margin-top: 10px;
    width: 280px;
    height: 48px;
    padding: 0;
  }

  .btn-social-login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto;
    svg {
      margin-right: 10px;
    }
  }
}
</style>
