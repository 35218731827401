const validateEmailAddress = (rule, value, callback) => {
  let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
  if (value === '') {
    callback(new Error('The email address field is required.'))
  } else if (reg.test(value) == false) {
    callback(new Error('The email address field is invalid.'))
  }else {
    callback()
  }
}

const validatePassword = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('The password field is required.'))
  }else {
    callback()
  }
}

const validateTerms = (rule, value, callback) => {
  if (value === false) {
    callback(new Error('You must agree to the terms of service and the privacy policy.'));
  }
  else {
    callback();
  }
};

export default {
  validateEmailAddress,
  validatePassword,
  validateTerms,
}
